import React, {useState, useEffect, useContext} from 'react';
import {useOutletContext} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CustomInputDate from '../Header/CustomInputDate';
import AppContext from '../../Context/AppContext';

const moment = require('moment');

const disableDays = (current) => {
  const june = moment().set({year: 2022, month: 4, date: 31}).toDate();
  const day = moment(current).day();
  return day !== 0 && day !== 6 && current >= june;
};

const formatDate = (date) => {
  const timestamp = Date.parse(date);
  if (Number.isNaN(timestamp)) return new Date();
  const selectedToMomentDay = moment(date, 'YYYY-MM-DD');
  const june = moment().set({year: 2022, month: 4, date: 31});
  const day = selectedToMomentDay.day();
  // If not weekend and after June 31st, return date
  if (day !== 0 && day !== 6 && !moment(selectedToMomentDay).isBefore(june)) {
    return selectedToMomentDay.toDate();
  }
  return new Date();
};

const ETFHeader = ({currentDate, etfGroups}) => {
  const [state, dispatch, wsSocket] = useOutletContext();
  const {groupCollection, groups} = state;
  const {updatingDate, setUpdatingDate} = useContext(AppContext);
  const [etfGroupsJoined, setEtfGroupsJoined] = useState(true);

  const handleMultiGroupDatePickerChange = (newDate) => {
    if (!groups?.length || !newDate) return;
    groups.forEach((gr) => {
      const {type: groupType, group: groupName} = gr;
      if (!groupType || !groupName) return;
      // const getGroup = groupCollection?.[groupName] ? {...groupCollection[groupName]} : null;
      // if (!getGroup) return;

      const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
      const groupToJoin = {
        group: `${groupName ? decodeURIComponent(groupName) : ''}`,
        date: newDate,
      };
      wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    });
    dispatch({type: 'CHANGE_MULTI_GROUP_DATE', payload: {newDate, groupCollection}});
    setEtfGroupsJoined(false);
  };

  const handleDatePickerChange = (date) => {
    if (!wsSocket) return;
    const newDate = moment(date).format('YYYY-MM-DD');
    if (setUpdatingDate) {
      setUpdatingDate(true);
    }
    handleMultiGroupDatePickerChange(newDate);
  };

  useEffect(() => {
    if (!Object.keys(groupCollection).length || !updatingDate || !etfGroups?.length) return;
    const groupCollectionKeys = Object.keys(groupCollection ?? {});
    const etfGroupsKeys = etfGroups.map((g) => g.group);
    const onlyETFGroupCollections = groupCollectionKeys.filter((k) => etfGroupsKeys.includes(k));
    const filteredKeys = onlyETFGroupCollections.filter((key) => {
      const gr = groupCollection[key];
      if (!gr) {
        return false;
      }
      if (!gr?.currentDate) {
        if (!gr?.sentDate) {
          return false;
        }
        if (gr?.sentDate && !gr?.recievedDate) {
          return false;
        }
        if (gr?.sentDate && gr?.recievedDate) {
          return gr?.sentDate !== gr?.recievedDate;
        }
      }
      return false;
    });
    if (!filteredKeys.length) {
      setUpdatingDate(false);
      setEtfGroupsJoined(true);
    }
  }, [groups, groupCollection]);

  return (
    <div className="mb-1 mx-auto group-header">
      <div className="flex justify-start gap-9 filter-wrapper">
        <div className="flex gap-7 date-pick-wrap">
          {updatingDate ? (
            <div className="lds-ring-date">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : etfGroupsJoined ? (
            <div className="flex items-center">
              <DatePicker
                filterDate={disableDays}
                selected={formatDate(currentDate ?? null)}
                onChange={handleDatePickerChange}
                customInput={<CustomInputDate />}
              />
            </div>
          ) : null}
        </div>
        <div className="flex items-center gap-4 pr-5 justify-end flex-1">
          <div className="flex justify-between mx-auto w-full delay-info text-right">
            {!JSON.parse(process.env?.REACT_APP_LIVE_DATA ?? 'false') ? (
              <p className="delay-info w-full ">
                <small>15 Minute Delay</small>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ETFHeader;
